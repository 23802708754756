<template>
  <div>
    <CompHeader title="账户与安全" />
    <div
      :class="[
        'bg',
        { bg1: num <= 80 && num > 60, bg2: num <= 100 && num > 80, bg3: num > 0 && (num <= 60 || num > 100) },
      ]"
    >
      <div class="dun">{{ num }}</div>
      <div class="text">您的账户安全分</div>
    </div>
    <div class="ul">
      <div class="li">
        <div :class="['img', { img1: have_password == 1 }]"></div>
        <div class="name">登录密码</div>
        <div class="text">
          {{
            have_password == 1
              ? "已设置"
              : "安全性高的密码可以使账号更安全，建议您定期更换密码，且设置一个包含数字和字母，并长度超过6位以上的密码。"
          }}
        </div>
        <div class="bt"  @click="setPassword">
          {{ have_password == 1 ? "更改密码" : "设置密码" }}
          <el-icon><ArrowRight /></el-icon>
        </div>
      </div>
      <div class="li">
        <div class="img img1"></div>
        <div class="name">绑定手机</div>
        <div class="text">
          <!-- 绑定手机后，您即可享受摩点丰富的手机服务，如手机登录、手机找回密码、开通手机动态密码等。 -->
          {{phoneNum || '已绑定'}}
        </div>
        <div class="bt"  @click="updateMobile">
          更换手机
          <el-icon><ArrowRight /></el-icon>
        </div>
      </div>
      <!-- <div class="li">
        <div :class="['img', { img1: userCard }]"></div>
        <div class="name">实名认证</div>
        <div class="text">
          {{
            userCard
              ? name
              : "提交实名信息可保护您的帐号安全，避免帐号被盗，花1分钟填写一下吧！"
          }}
        </div>
        <div class="bt"  @click="appFn">
          {{ userCard ? "查看" : "实名认证" }}
          <el-icon><ArrowRight /></el-icon>
        </div>
      </div> -->
      <div class="li">
        <div :class="['img', { img1: weixin }]"></div>
        <div class="name">绑定微信账号</div>
        <div class="text">
          {{ weixin ? weixin.nickname : "未绑定微信账号" }}
        </div>
        <div v-if="weixin" class="bt"  @click="unBind('4')">
          取消绑定
          <el-icon><ArrowRight /></el-icon>
        </div>
        <div v-else class="bt"  @click="bindWX">
          绑定微信
          <el-icon><ArrowRight /></el-icon>
        </div>
      </div>
      <div class="li">
        <div :class="['img', { img1: weibo }]"></div>
        <div class="name">绑定新浪微博</div>
        <div class="text">
          {{ weibo ? weibo.nickname : "未绑定微博账号" }}
        </div>
        <div v-if="weibo" class="bt" @click="unBind('1')">
          取消绑定
          <el-icon><ArrowRight /></el-icon>
        </div>
        <div v-else class="bt" @click="bindWB">
          绑定微博
          <el-icon><ArrowRight /></el-icon>
        </div>
      </div>
    </div>
    <OpenApp ref="openapp" />
  </div>
</template>

<script>
import Cookies from 'js-cookie'
// eslint-disable-next-line no-unused-vars
import { get, post } from "@/utils/apiBase.js";
import { getAuthStateInfo } from "@api";
import OpenApp from "@@/common/openApp.vue";
import CompHeader from "@@/common/header.vue";
import { unbinding } from "@api/index"
export default {
  data() {
    return {
      user_id: "",
      have_password: 0,
      userCard: false,
      weibo: null,
      name: "",
      weixin: null,
      show: false, 
      num: 0
    };
  },
  computed: {
    // num() {
    //   let num = 0;
    //   if (this.have_password == 1) {
    //     num += 10;
    //   }
    //   if (this.weixin) {
    //     num += 15;
    //   }
    //   if (this.weibo) {
    //     num += 15;
    //   }
    //   // if (this.userCard) {
    //   //   num + 20;
    //   // }
    //   return num;
    // },
    phoneNum() {
      const num = Cookies.get('MDPHONE' || '')
      return num ? num.replace(num.substring(3,7), "****") : ''
    }
  },
  components: {
    CompHeader,
    OpenApp,
  },
  mounted() {
    this.user_id = this.$store.state.user.userId;
    const _this = this
    Promise.all([this.bind_list(),this.check_password()]).finally(() => {
      // this.show = true
      if (_this.have_password == 1) {
        _this.num += 10;
      }
      if (_this.weixin) {
        _this.num += 15;
      }
      if (_this.weibo) {
        _this.num += 15;
      }
      _this.num += 60
    })
    // this.bind_list();
    // this.check_password();
    this.get_auth_state_info();
  },
  methods: {
    appFn() {
      this.$refs.openapp.open();
    },
    async bind_list() {
      const { data } = await get("/account/bind_list", {});
      this.weibo = data && data.weibo || null;
      this.weixin = data && data.weixin || null;
    },
    async check_password() {
      const { data } = await get("/account/check_password", {});
      this.have_password = data.have_password;
    },
    async get_auth_state_info() {
      const { data } = await getAuthStateInfo({
        to_user_id: this.user_id,
      });

      if (data.auth_cate == 11 && data.auth_status_code == 200) {
        this.userCard = true;
        this.name = this.noPassByName(data.realname);
      }
    },
    noPassByName(str) {
      if (null != str && str != undefined) {
        if (str.length == 2) {
          return str.substring(0, 1) + "*"; //截取name 字符串截取第一个字符，
        } else if (str.length == 3) {
          return str.substring(0, 1) + "*" + str.substring(2, 3); //截取第一个和第三个字符
        } else if (str.length > 3) {
          return str.substring(0, 1) + "*" + "*" + str.substring(3, str.length); //截取第一个和大于第4个字符
        }
      } else {
        return "";
      }
    },
    // 绑定微信
    bindWX() {
      const appid = 'wx50b160881d9a15ed'
      const url = 'https://login.modian.com/u/account_binding?type=weixin'
      // const url = 'https://www.modian.com/user/security'
      const link = `https://open.weixin.qq.com/connect/qrconnect?appid=${appid}&redirect_uri=${encodeURIComponent(url)}&response_type=code&scope=snsapi_login&state=${Math.random(1000, 9999)}#wechat_redirect`

      location.href = link
    },
    // 绑定微博
    bindWB() {
      const appid = '3249442221'
      const url = 'https://login.modian.com/u/account_binding?type=weibo'
      const link = `https://api.weibo.com/oauth2/authorize?client_id=${appid}&response_type=code&redirect_uri=${url}&scope=email,follow_app_official_microblog`

      location.href = link
    },
    // 解绑
    unBind(type) {
      this.$confirm('您确定要解除绑定吗？解除后将不能使用此社交账号登录摩点', '风险提示', {
        confirmButtonText: '解除绑定',
        cancelButtonText: '取消',
        autofocus: false,
      }).then(() => {
        unbinding({
          user_id: this.user_id,
          platform: type
        }).then(res => {
          if(res.status == 0) {
            this.$message.success('解绑成功');
            location.reload()
          } else {
            this.$message.error(res.message);
          }
        })
      }).catch(() => {
        this.$message.info('已取消');          
      });
    },
    setPassword() {
      // 更改密码
      if (this.have_password == 1) {
        this.$router.push("/user/security/resetpassword");
      } else {
        this.$router.push("/user/security/setpassword");
      }
    },
    // 更改手机号
    updateMobile() {
      this.$router.push("/user/security/updatemobile");
    }
  },
};
</script>
<style lang="scss" scoped>
.bg {
  margin-top: 20px;
  height: 170px;
  // background: linear-gradient(180deg, #9f9f9f 0%, #dfdfdf 100%);
  background: #fff;
  border-radius: 8px 8px 8px 8px;
  padding-top: 20px;
  &.bg1 {
    background: linear-gradient(180deg, #ea7b2f 0%, #fac465 100%);
  }
  &.bg2 {
    background: linear-gradient(180deg, #4f74e5 0%, #84cef3 100%);
  }
  &.bg3 {
    background: linear-gradient(180deg, #ef3f3e 0%, #ff9476 100%);
  }
  .dun {
    width: 95px;
    height: 95px;
    line-height: 95px;
    text-align: center;
    font-size: 20px;
    font-family: Avenir-Heavy, Avenir;
    font-weight: 800;
    color: #ffffff;
    margin: 0 auto;
    background: url("~@assets/images/dun.png") center/cover no-repeat;
  }
  .text {
    height: 18px;
    font-size: 17px;
    font-family: PingFang SC-中粗体, PingFang SC;
    font-weight: normal;
    color: #ffffff;
    line-height: 18px;
    margin-top: 11px;
    text-align: center;
  }
}
.li {
  height: 110px;
  display: grid;
  align-items: center;
  grid-template-columns: 40px 187px 397px 171px;
  grid-template-rows: 110px;
  border-bottom: 1px solid #e8e8e8;
  .img {
    height: 40px;
    width: 40px;
    background: url("~@assets/images/user/icon1.png") center/cover no-repeat;
    &.img1 {
      background: url("~@assets/images/user/icon11.png") center/cover no-repeat;
    }
  }

  .name {
    font-size: 16px;
    font-family: PingFang SC-常规体, PingFang SC;
    font-weight: normal;
    color: #1a1a1a;
    line-height: 16px;
    margin-left: 10px;
  }
  .text {
    font-size: 14px;
    font-family: PingFang SC-常规体, PingFang SC;
    font-weight: normal;
    color: #7f7f7f;
    line-height: 20px;
  }
  .bt {
    display: flex;
    align-items: center;
    justify-content: right;
    font-size: 16px;
    font-family: PingFang SC-常规体, PingFang SC;
    font-weight: normal;
    color: #00c4a1;
    line-height: 16px;
    cursor: pointer;
  }
}
.ul {
  .li:nth-of-type(2) {
    .img {
      background: url("~@assets/images/user/icon2.png") center/cover no-repeat;
      &.img1 {
        background: url("~@assets/images/user/icon21.png") center/cover
          no-repeat;
      }
    }
  }
  // .li:nth-of-type(3) {
  //   .img {
  //     background: url("~@assets/images/user/icon3.png") center/cover no-repeat;
  //     &.img1 {
  //       background: url("~@assets/images/user/icon31.png") center/cover
  //         no-repeat;
  //     }
  //   }
  // }
  .li:nth-of-type(3) {
    .img {
      background: url("~@assets/images/user/icon4.png") center/cover no-repeat;
      &.img1 {
        background: url("~@assets/images/user/icon41.png") center/cover
          no-repeat;
      }
    }
  }
  .li:nth-of-type(4) {
    .img {
      background: url("~@assets/images/user/icon5.png") center/cover no-repeat;
      &.img1 {
        background: url("~@assets/images/user/icon51.png") center/cover
          no-repeat;
      }
    }
  }
}
</style>
