<script setup>
import { ElMessageBox } from "element-plus";
// eslint-disable-next-line no-unused-vars
const open = () => {
  ElMessageBox.alert(
    " <div class='img'></div><p class='p'>扫码去“摩点App”进行更多操作</p>",
    "提示",
    {
      customClass: 'openappbox',
      confirmButtonText: "确定",
      dangerouslyUseHTMLString: true,
    }
  );
};
// eslint-disable-next-line no-undef
defineExpose({ open });
</script>
<style  lang="scss">
.openappbox {
  .img {
    width: 160px;
    height: 160px;
    margin: 0 auto;
    background: url("~@assets/images/QR@2x.png") center/cover no-repeat;
  }
  .p {
      font-size: 16px;
      font-family: PingFang SC-常规体, PingFang SC;
      font-weight: normal;
      color: #7f7f7f;
      line-height: 24px;
      text-align: center;
      margin-top: 17px;
    }
    .el-message-box__btns{
      display: flex;
      justify-content: center;
      padding-top: 0 !important;
    }
}
</style>

